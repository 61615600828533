<template>
  <div class="pps-icon">
    <svg
      t="1724756128299"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4510"
      width="20"
      height="20"
    >
      <path
        d="M224.2 477.8c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2s3.3 17.5 10 24.2c6.7 6.7 14.7 10 24.2 10 9.4 0 17.5-3.3 24.2-10 6.7-6.7 10-14.7 10-24.2s-3.3-17.5-10-24.2c-6.7-6.7-14.8-10-24.2-10zM863.8 701.7H160.2c-27.1 0-50.2 9.6-69.4 28.8C71.6 749.6 62 772.8 62 799.8v64c0 27.1 9.6 50.2 28.8 69.4 19.2 19.2 42.3 28.8 69.4 28.8h703.6c27.1 0 50.2-9.6 69.4-28.8 19.2-19.2 28.8-42.3 28.8-69.4v-64c0-27.1-9.6-50.2-28.8-69.4-19.2-19.1-42.3-28.7-69.4-28.7z m29.8 162.1c0 8.2-2.9 15.2-8.7 21-5.8 5.8-12.8 8.7-21 8.7H160.2c-8.2 0-15.2-2.9-21-8.7-5.8-5.8-8.7-12.8-8.7-21v-64c0-8.2 2.9-15.2 8.7-21 5.8-5.8 12.8-8.7 21-8.7h703.6c8.2 0 15.2 2.9 21 8.7 5.8 5.8 8.7 12.8 8.7 21v64z"
        p-id="4511"
        fill="#515151"
      ></path>
      <path
        d="M799.8 797.6H671.9c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2 0 9.4 3.3 17.5 10 24.2 6.7 6.7 14.7 10 24.2 10h127.9c9.4 0 17.5-3.3 24.2-10 6.7-6.7 10-14.8 10-24.2 0-9.4-3.3-17.5-10-24.2-6.6-6.6-14.7-10-24.2-10zM224.2 797.6c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2 0 9.4 3.3 17.5 10 24.2 6.7 6.7 14.7 10 24.2 10 9.4 0 17.5-3.3 24.2-10 6.7-6.7 10-14.8 10-24.2s-3.3-17.5-10-24.2c-6.7-6.6-14.8-10-24.2-10zM352.1 797.6c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2s3.3 17.5 10 24.2c6.7 6.7 14.8 10 24.2 10s17.5-3.3 24.2-10c6.7-6.7 10-14.8 10-24.2 0-9.4-3.3-17.5-10-24.2-6.7-6.6-14.8-10-24.2-10zM799.8 157.9H671.9c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2 0 9.4 3.3 17.5 10 24.2 6.7 6.7 14.7 10 24.2 10h127.9c9.4 0 17.5-3.3 24.2-10 6.7-6.7 10-14.7 10-24.2s-3.3-17.5-10-24.2c-6.6-6.6-14.7-10-24.2-10zM352.1 157.9c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2s3.3 17.5 10 24.2c6.7 6.7 14.8 10 24.2 10s17.5-3.3 24.2-10c6.7-6.7 10-14.7 10-24.2 0-9.4-3.3-17.5-10-24.2-6.7-6.6-14.8-10-24.2-10z"
        p-id="4512"
        fill="#515151"
      ></path>
      <path
        d="M933.2 90.8C914 71.6 890.9 62 863.8 62H160.2c-27.1 0-50.2 9.6-69.4 28.8C71.6 110 62 133.1 62 160.2v64c0 27.1 9.6 50.2 28.8 69.4 19.2 19.2 42.3 28.8 69.4 28.8h703.6c27.1 0 50.2-9.6 69.4-28.8 19.2-19.2 28.8-42.3 28.8-69.4v-64c0-27.1-9.6-50.2-28.8-69.4z m-39.6 133.4c0 8.2-2.9 15.2-8.7 21-5.8 5.8-12.8 8.7-21 8.7H160.2c-8.2 0-15.2-2.9-21-8.7-5.8-5.8-8.7-12.8-8.7-21v-64c0-8.2 2.9-15.2 8.7-21 5.8-5.8 12.8-8.7 21-8.7h703.6c8.2 0 15.2 2.9 21 8.7 5.8 5.8 8.7 12.8 8.7 21v64z"
        p-id="4513"
        fill="#515151"
      ></path>
      <path
        d="M224.2 157.9c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2s3.3 17.5 10 24.2c6.7 6.7 14.7 10 24.2 10 9.4 0 17.5-3.3 24.2-10 6.7-6.7 10-14.7 10-24.2s-3.3-17.5-10-24.2c-6.7-6.6-14.8-10-24.2-10zM799.8 477.8H671.9c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2 0 9.4 3.3 17.5 10 24.2 6.7 6.7 14.7 10 24.2 10h127.9c9.4 0 17.5-3.3 24.2-10 6.7-6.7 10-14.7 10-24.2s-3.3-17.5-10-24.2c-6.6-6.7-14.7-10-24.2-10z"
        p-id="4514"
        fill="#515151"
      ></path>
      <path
        d="M863.8 381.8H160.2c-27.1 0-50.2 9.6-69.4 28.8C71.6 429.8 62 452.9 62 480v64c0 27.1 9.6 50.2 28.8 69.4 19.2 19.2 42.3 28.8 69.4 28.8h703.6c27.1 0 50.2-9.6 69.4-28.8 19.2-19.2 28.8-42.3 28.8-69.4v-64c0-27.1-9.6-50.2-28.8-69.4-19.2-19.2-42.3-28.8-69.4-28.8zM893.6 544c0 8.2-2.9 15.2-8.7 21-5.8 5.8-12.8 8.7-21 8.7H160.2c-8.2 0-15.2-2.9-21-8.7-5.8-5.8-8.7-12.8-8.7-21v-64c0-8.2 2.9-15.2 8.7-21 5.8-5.8 12.8-8.7 21-8.7h703.6c8.2 0 15.2 2.9 21 8.7 5.8 5.8 8.7 12.8 8.7 21v64z"
        p-id="4515"
        fill="#515151"
      ></path>
      <path
        d="M352.1 477.8c-9.4 0-17.5 3.3-24.2 10-6.7 6.7-10 14.7-10 24.2s3.3 17.5 10 24.2c6.7 6.7 14.8 10 24.2 10 9.4 0 17.5-3.3 24.2-10 6.7-6.7 10-14.7 10-24.2s-3.3-17.5-10-24.2c-6.7-6.7-14.8-10-24.2-10z"
        p-id="4516"
        fill="#515151"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-host'
};
</script>

<style></style>
